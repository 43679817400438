<template>
    <div>
        <v-row no-gutters>
            <v-col class="d-flex flex-row align-center">
                <v-btn-toggle v-model="fscReportsCurrentTab" tile group color="success darken-3 accent-3 ml-0">
                    <v-btn :value="1" class="ml-0">{{ $t('message.annualInAndOut') }}</v-btn>
                    <v-btn :value="2">{{ $t('message.productPortToPort') }}</v-btn>
                    <v-btn :value="3">{{ $t('message.supplierList') }}</v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
        <div>
            <FscAnnualInOutList v-show="fscReportsCurrentTab == 1"></FscAnnualInOutList>
            <FscProductPortToPortList v-show="fscReportsCurrentTab == 2"></FscProductPortToPortList>
            <FscSupplierList v-show="fscReportsCurrentTab == 3"></FscSupplierList>
        </div>
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
const FscAnnualInOutList = () => import("Components/Appic/FscPefc/FscAnnualInOutList");
const FscProductPortToPortList = () => import("Components/Appic/FscPefc/FscProductPortToPortList");
const FscSupplierList = () => import('Components/Appic/FscPefc/FscSupplierList');

export default {
    name: "FscReports",
    components: {FscAnnualInOutList, FscProductPortToPortList, FscSupplierList },
    data() {
        return {
            currentReport: {

            }
        }
    },
    computed: {
        ...mapFields('runtime',{
            fscReportsCurrentTab: 'fscReportsCurrentTab'
        })
    },
    methods: {
        exportTable() {

        }
    },
    mounted() {
        if(this.fscReportsCurrentTab == null) this.fscReportsCurrentTab = 1
    }
}
</script>

<style scoped>

</style>